import { ConnectionHandler } from "./ConnectionHandler";
import { LOGGER } from "./Logger";
import { TOO_EARLY_TOKEN_RENEWAL_LAMBDA, TOO_EARLY_UPDATE_LAMBDA, TOO_EARLY_DISCONNECT_LAMBDA } from "./main";
import { SignalState } from "./models/SignalState"
import { DeviceManager } from "./DeviceManager";

export class DomManager {

  constructor(
    private connectionHandler: ConnectionHandler,
    public myStream: MediaStream,
    private signalState: SignalState) {

    window.removeEventListener('webrtc.update', TOO_EARLY_UPDATE_LAMBDA)
    window.addEventListener('webrtc.update', (e) => {
      LOGGER.log(`webrtc.updateEntityId: ${JSON.stringify(e)}`)
      if (localStorage.getItem('webrtc.entityId') != null) {
        this.signalState.id.entityId = localStorage.getItem('webrtc.entityId')
      }
      if (localStorage.getItem('webrtc.deviceId') != null) {
        this.signalState.id.deviceId = localStorage.getItem('webrtc.deviceId')
      }
      
        // Change the microphone
        // this.updateStream();
      
      LOGGER.log(`webrtc.updateEntityId result: ${JSON.stringify({ id: this.signalState.id, token: this.signalState.token })}`)
      // AIG DEBUG
      // this.updateStream();
    })
    LOGGER.log('webrtc.update added event listener')

    // register right event listener for tokenRenewed.
    window.removeEventListener('webrtc.tokenRenewed', TOO_EARLY_TOKEN_RENEWAL_LAMBDA)
    window.addEventListener('webrtc.tokenRenewed', (e) => {
      LOGGER.log(`webrtc.tokenRenewed: ${JSON.stringify(e)}`)
      this.signalState.connectionHandler.renewClient()
    })
    LOGGER.log('webrtc.tokenRenewed added event listener')


    window.removeEventListener('webrtc.disconnect', TOO_EARLY_DISCONNECT_LAMBDA)
    window.addEventListener('webrtc.disconnect', (e) => {
      LOGGER.log(`webrtc.disconnect: ${JSON.stringify(e)}`)
      this.signalState.connectionHandler.disconnectClient();
    })

    // Set new mic
    window.addEventListener('webrtc.stream.update', (e) => {
      setTimeout(async () => {

        this.signalState.connectionHandler.upodateStream();
      }, 300) // setTimeout() shouldn't really be neccessary. IBI fix...

    })
  }


  entityIdToAudioTagId = (id: string) => `audio-in-${id}`

  createAudioTag = (otherId: string) => {
    let audioTag = document.getElementById(this.entityIdToAudioTagId(otherId)) as HTMLAudioElement
    if (audioTag === null) {
      audioTag = document.createElement('audio')
      audioTag.setAttribute("id", this.entityIdToAudioTagId(otherId))
      document.body.appendChild(audioTag)
    }
    return audioTag
  }

  configAudioTag = (otherId: string, callType: string, role: string) => {

    const audioTag = this.createAudioTag(otherId)

    // if (callType === 'panic' && role === 'responder') {
    //   // audioTag.muted = true
    //   // window.setTimeout(() => audioTag.muted = false, 10000) // The server will handle this?
    //   //this.myStream.getAudioTracks()[0].enabled = false
    //   // this.myStream.getAudioTracks().forEach(track => track.enabled = false) // Mute all mics
    // } 

    const remoteStream = new MediaStream()
    audioTag.srcObject = remoteStream

    return remoteStream

  }

}