export class DeviceManager {

  async getMediaStream(): Promise<MediaStream> {
      try {
          let ClientDevices = JSON.parse(localStorage.getItem('IBISelectedDevices'));
          let audioSource = null;
      
          if(ClientDevices){
            audioSource = ClientDevices.input;
          }
          console.log('[SIGNAL-CLIENT] IBIDevices audioSource INIT', audioSource);
          const constraints = {
            audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
            video: false,
          };
          return await navigator.mediaDevices.getUserMedia(constraints);
      
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error: Error): void {
    console.log("[SIGNAL-CLIENT] DeviceManager error: ", error.message, error.name);
  }

  checkMicSettings(stream: MediaStream): void {
    const audioTrack = stream.getAudioTracks()[0];
    const settings = audioTrack.getSettings();
    console.log(`[SIGNAL-CLIENT] Microphone device ID: ${settings.deviceId}`, audioTrack)
  }
}
