import { DomManager } from "./DomManager";
import { LOGGER } from "./Logger";
import { PeerId } from "./models/PeerId";
import { PeerRepository } from "./models/PeerRepository"
import { SignalState } from "./models/SignalState";

import { DeviceManager } from "./DeviceManager";
export class SocketHandler {

  private iceServers: RTCIceServer[]

  constructor(
    private client: SocketIOClient.Socket, 
    private peers: PeerRepository, 
    private signalState: SignalState,
    private domManager: DomManager,
    private myStream: MediaStream) {
    
  }


  createPeer (id: PeerId, callType: string) : RTCPeerConnection {
    const p = new RTCPeerConnection({
      iceServers: this.iceServers,
    })

    p.onicecandidate = (e) => {
      if (e.candidate) {
        const payload = {
          target: {id},
          caller: { id: this.signalState.id},
          candidate: e.candidate,
        }
        this.client.emit('ice-candidate', payload);
      }
    }

    // add track to audio tag
    p.ontrack = (e) => {
      LOGGER.log(`track: ${JSON.stringify(e)}`)

      this.peers[id.entityId].track = e.track
      this.peers[id.entityId].stream.addTrack(e.track)
    }


    p.onnegotiationneeded = async () => {

      const offer = await p.createOffer()
      await p.setLocalDescription(offer)

      const payload = {
        target: {id}, // {customerId: string, entityId: string}
        caller: {id: this.signalState.id},
        sdp: p.localDescription,
        config: {type: callType}
      }
      this.client.emit('offer', payload)
    }

    return p
  }

  init() {

    this.client.on('endP2pConnection', (payload: any) => {
      LOGGER.log(`endP2pConnection: ${JSON.stringify(payload)}`)
      try{
        this.peers[payload.other.id.entityId].peerConnection.close()
      } catch(e) {
      }
      if(this.peers[payload.other.id.entityId].track != null) {
        // if track is not null, means that the other end of the connection, have added a track to the connection. 
        this.peers[payload.other.id.entityId].stream.removeTrack(this.peers[payload.other.id.entityId].track)
      }
      document.getElementById(this.peers[payload.other.id.entityId].tagId).remove()
      

      delete this.peers[payload.other.id.entityId]
      LOGGER.log(`this.peers: ${JSON.stringify(this.peers)}`)
      
    })

    this.client.on('iceServersResponse', (payload: any) => {
      LOGGER.log(`iceServersResponse: ${JSON.stringify(payload)}`)
      this.iceServers = payload.iceServers
      this.signalState.initialLoad = false
    })

    this.client.on('establishP2pConnection', (payload: any) => {
      LOGGER.log(`establishP2pConnection: ${JSON.stringify(payload)}`)

      const otherId: PeerId = payload.other.id

      this.peers[otherId.entityId] = {peerConnection: null, stream: null, tagId: null, track: null}

      this.peers[otherId.entityId].stream = this.domManager.configAudioTag(otherId.entityId, payload.config.type, 'caller')
      this.peers[otherId.entityId].tagId = this.domManager.entityIdToAudioTagId(otherId.entityId)
      this.peers[otherId.entityId].peerConnection = this.createPeer(otherId, payload.config.type)

      this.myStream.getAudioTracks().forEach((track) => this.peers[otherId.entityId].peerConnection.addTrack(track, this.myStream))

      LOGGER.log(`this.peers: ${JSON.stringify(this.peers)}`)
    })

    this.client.on('offer', async (offer: any) => {
      LOGGER.log(`offer: ${JSON.stringify(offer)}`)

      this.peers[offer.caller.id.entityId] = {peerConnection: null, stream: null, tagId: null, track: null}

      this.peers[offer.caller.id.entityId].stream = this.domManager.configAudioTag(offer.caller.id.entityId, offer.config.type, 'responder')
      this.peers[offer.caller.id.entityId].tagId = this.domManager.entityIdToAudioTagId(offer.caller.id.entityId)
      this.peers[offer.caller.id.entityId].peerConnection = this.createPeer(offer.caller.id, offer.config.type)

      const desc = new RTCSessionDescription(offer.sdp)
      await this.peers[offer.caller.id.entityId].peerConnection.setRemoteDescription(desc)

      if (offer.config.type !== 'broadcast') {
        this.myStream.getAudioTracks().forEach((track) => this.peers[offer.caller.id.entityId].peerConnection.addTrack(track, this.myStream))
      }


      const answer = await this.peers[offer.caller.id.entityId].peerConnection.createAnswer()
      await this.peers[offer.caller.id.entityId].peerConnection.setLocalDescription(answer)

      const payload = {
        target: offer.caller,
        caller: { id: this.signalState.id},
        sdp: this.peers[offer.caller.id.entityId].peerConnection.localDescription,
      }
      this.client.emit('answer', payload);
    })

    this.client.on('answer', async (incoming: any) => {
      LOGGER.log(`answer: ${JSON.stringify(incoming)}`)

      const desc = new RTCSessionDescription(incoming.sdp)

      try {
        await this.peers[incoming.caller.id.entityId].peerConnection.setRemoteDescription(desc)
      } catch(e) {
        console.error(e)
      }
    })

    this.client.on('ice-candidate', async (incoming: any) => {
      LOGGER.log(`ice-candidate: ${JSON.stringify(incoming)}`)
      const candidate = new RTCIceCandidate(incoming.candidate)
      try{
        await this.peers[incoming.caller.id.entityId].peerConnection.addIceCandidate(candidate)
      } catch(e) {
        console.error(e)
      }
    })

    this.client.on('setMuted', ({muted}: {muted: boolean}) => {
      LOGGER.log(`setMuted: ${JSON.stringify(muted)}`)
      this.myStream.getAudioTracks().forEach(track => track.enabled = !muted)
    })

  }

  async updateStream(){
    const devicemanager = new DeviceManager()
    this.myStream = await devicemanager.getMediaStream()
    }
}