import { Manager } from 'socket.io-client'
import { DomManager } from './DomManager'
import { LOGGER } from './Logger'
import { PeerRepository } from './models/PeerRepository'
import { SignalState } from './models/SignalState'
import { SocketHandler } from './SocketHandler'


const ENTITY_TYPE = "Browser";


export class ConnectionHandler {

  private socketClient: SocketIOClient.Socket
  private socketHandler: SocketHandler
  // private manager: SocketIOClient.Manager

  renewClient() {
    if (localStorage.getItem('webrtc.token') != null) {
      this.signalState.token = localStorage.getItem('webrtc.token')
    }

    //this.socketClient.close()
    this.socketClient.io.opts.query = {
      "entityType": ENTITY_TYPE,
      "entityId": this.signalState.id.entityId,
      "deviceId": this.signalState.id.deviceId,
      "token": this.signalState.token
    }

    // this.socketClient.connect()
    // client = require('socket.io-client')('https://voice.ibi.multiq.com', { query: `entityType=${ENTITY_TYPE}&entityId=${signalState.id.entityId}&deviceId=${signalState.id.deviceId}&token=${signalState.token}` })

    if(!this.socketClient.connected) {
      this.socketClient.connect()
    }
  }

  disconnectClient() {
      this.socketClient.close();
  }

  
  constructor(
    private signalState: SignalState, 
    peers: PeerRepository, 
    domManager: DomManager, 
    myStream: MediaStream) {
    // this.socketClient = require('socket.io-client')('https://voice.ibi.multiq.com', { autoConnect: false, query: `entityType=${ENTITY_TYPE}&entityId=${this.signalState.id.entityId}&deviceId=${this.signalState.id.deviceId}&token=${this.signalState.token}` })

    let signalServerUrl = 'https://voice.ibi.multiq.com'

    if(!localStorage.getItem('webrtc.signalServerUrl')) {
      LOGGER.log(`using default signal server url: ${signalServerUrl}`)
    } else {
      signalServerUrl = localStorage.getItem('webrtc.signalServerUrl')
    }

    this.socketClient = require('socket.io-client')(signalServerUrl, {
      autoConnect: false,
      query: {
        "entityType": ENTITY_TYPE,
        "entityId": this.signalState.id.entityId,
        "deviceId": this.signalState.id.deviceId,
        "token": this.signalState.token
      }
    })

    this.socketHandler = new SocketHandler(this.socketClient, peers, signalState, domManager, myStream)

    this.socketHandler.init()
    // this.socketHandler.updateStream()
    this.socketClient.on('connect', () => {
      LOGGER.log(`connected to signal server: ${JSON.stringify(this.signalState.id)}`)
      this.socketClient.emit('iceServersRequest')
    })
    

  }


  upodateStream(){
    this.socketHandler.updateStream()
  }
  

}