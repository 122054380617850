import { Socket, Manager } from "socket.io-client"
import { DomManager } from "./DomManager"
import { PeerRepository } from "./models/PeerRepository"
import { SignalState } from "./models/SignalState";
import { SocketHandler } from "./SocketHandler"
import { ConnectionHandler } from './ConnectionHandler'
import { LOGGER } from "./Logger";
import { DeviceManager } from "./DeviceManager"

export const TOO_EARLY_TOKEN_RENEWAL_LAMBDA = () => {
  LOGGER.log(`webrtc.tokenRenewed received too early. Please consider calling it later once the webrtc client is fully loaded.`)
}

export const TOO_EARLY_UPDATE_LAMBDA = () => {
  LOGGER.log(`webrtc.update received too early. Please consider calling it later once the webrtc client is fully loaded.`)
}

export const TOO_EARLY_DISCONNECT_LAMBDA = () => {
  LOGGER.log(`webrtc.disconnect received too early. Please consider calling it later once the webrtc client is fully loaded.`)
}


document.addEventListener("DOMContentLoaded", () => {
  LOGGER.log('DOMContentLoaded');

  (async () => {
    try {
      
      window.addEventListener('webrtc.tokenRenewed', TOO_EARLY_TOKEN_RENEWAL_LAMBDA)
      window.addEventListener('webrtc.update', TOO_EARLY_UPDATE_LAMBDA)
      window.addEventListener('webrtc.disconnect', TOO_EARLY_DISCONNECT_LAMBDA)

      const signalState: SignalState = { id: {entityId: null, deviceId: null}, token: null, initialLoad: true, connectionHandler: null}

      if(localStorage.getItem('webrtc.entityId') != null) {
        signalState.id.entityId = localStorage.getItem('webrtc.entityId')
      }

      if(localStorage.getItem('webrtc.deviceId') != null) {
        signalState.id.deviceId = localStorage.getItem('webrtc.deviceId')
      }

      if(localStorage.getItem('webrtc.token') != null) {
        signalState.token = localStorage.getItem('webrtc.token')
      }
  
      const peers: PeerRepository = {}  

      const devicemanager = new DeviceManager();
      let myStream = await devicemanager.getMediaStream()

      const domManager = new DomManager(signalState.connectionHandler, myStream, signalState)
      signalState.connectionHandler = new ConnectionHandler(signalState, peers, domManager, myStream)


    } catch (e) {
        // Deal with the fact the chain failed
        console.error(e)
    }

  })().then( () => { setTimeout( ()=>{window.dispatchEvent(new Event('webrtc.ready'))}), 200 } ); // setTimeout() shouldn't really be neccessary 
});



